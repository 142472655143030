<template>
  <div class="banner">
    <div class="overlay"></div>
    <div class="container">
      <h1>SITCOM Int</h1>
      <p>Expert en Digitalisation et Innovation numerique</p>
      <div class="buttons">
        <button @click="navigation('contact')" class="btn btn-success">
          Contacts
        </button>
        <!-- <button class="btn btn-primary">Nos solutions</button> -->
        <button class="btn btn-primary">A propos</button>
      </div>
    </div>
  </div>
  <div class="container">
    <Solutions />
    <Services />
  </div>
</template>

<script>
import Services from "./Services.vue";
import Solutions from "./Solutions.vue";
export default {
  name: "HomeView",
  components: {
    Solutions,
    Services,
  },

  methods: {
    navigation(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style scoped>
.banner {
  position: relative;
  height: 500px; /* Ajustez la hauteur selon vos besoins */
  background-image: url("https://www.autocar.co.uk/sites/autocar.co.uk/files/range-rover-sport-sv-review-2024-01-cornering-front.jpg"); /* Ajoutez le chemin de votre image */
  background-image: url("../assets/images/OIG1.2bfiriisCPQ.jpeg"); /* Ajoutez le chemin de votre image */
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Ajoutez une superposition semi-transparente */
}

.container {
  z-index: 1;
}

h1 {
  font-size: 3rem;
}

p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn {
  padding: 10px 20px;
  font-size: 1.2rem;
}
</style>
