// data.js

const data = {
  solutions: [
    {
      id: 101,
      nom: "SOLUTION GMAT",
      titre:
        "Solution de Gestion des équipements et suivies des maintenances et consommations",
      description:
        "GMAT a été développé par SITCOM Int, un cabinet informatique spécialisé dans le développement de logiciels métiers pour les entreprises. Vous pouvez contacter sitcom pour acquérir le logiciel ou pour une démonstration.",

      fonctionnalites: [
        {
          titre: "Le module de gestion des équipements",
          contenu:
            " La gestion des équipements inclut la maintenance, les visites, les en carburant ou autres consommables ainsi que les statistiques périodiques de ces opérations. Les maintenances peuvent aussi inclure des consommations ou les dépenses supplémentaires. Pour les structures disposant de leur propre garage, le GMAT permet la gestion des maintenanciers.",
          image:
            "https://i.gaw.to/content/photos/60/79/607943-range-rover-sport-sv-edition-one-2024-626-chevaux-sur-circuit.jpeg",
        },
      ],
    },
    {
      id: 102,
      nom: "SOLUTION BUDGET",
      titre:
        " Solution de gestion de Budget et de suivie des engagements budgétaires",
      description:
        "GMAT a été développé par SITCOM Int, un cabinet informatique spécialisé dans le développement de logiciels métiers pour les entreprises. Vous pouvez contacter sitcom pour acquérir le logiciel ou pour une démonstration.",

      fonctionnalites: [
        {
          titre: "Le module de gestion des équipements",
          contenu:
            " La gestion des équipements inclut la maintenance, les visites, les en carburant ou autres consommables ainsi que les statistiques périodiques de ces opérations. Les maintenances peuvent aussi inclure des consommations ou les dépenses supplémentaires. Pour les structures disposant de leur propre garage, le GMAT permet la gestion des maintenanciers.",
          image:
            "https://i.gaw.to/content/photos/60/79/607943-range-rover-sport-sv-edition-one-2024-626-chevaux-sur-circuit.jpeg",
        },
      ],
    },
  ],
};

export default data;
