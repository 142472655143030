<template>
  <div>
    <h1 class="titre">Nos solutions logicielles</h1>
    <div class="card-list">
      <div @click="navigation('gmat')" class="card gmat">
        <div class="info">
          <h2>SOLUTION GMAT</h2>
          <div>
            Solution de Gestion des équipements et suivies des maintenances et
            consommations
          </div>
        </div>
      </div>
      <div @click="navigation('budget')" class="card budget">
        <div class="info">
          <h2>SOLUTION BUDGET</h2>
          <div>
            Solution de gestion de Budget et de suivie des engagements
            budgétaires
          </div>
        </div>
      </div>
      <div @click="navigation('vware')" class="card vware">
        <div class="info">
          <h2>SOLUTION VWARE</h2>
          <div>Solution de gestion de ventes</div>
        </div>
      </div>
      <div @click="navigation('buronumerik')" class="card buronumerik">
        <div class="info">
          <h2>BURONUMERIK</h2>
          <div>Solution intégrée de gestion d'entreprise</div>
        </div>
      </div>
      <div @click="navigation('kamaged')" class="card kamaged">
        <div class="info">
          <h2>KAMAGED SI-ECM</h2>
          <div>Logiciel d'archivage electronique</div>
        </div>
      </div>

      <div @click="navigation('dataged')" class="card dataged">
        <div class="info">
          <h2>DATAGED SAE</h2>
          <div>DATAGED SAE</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from "../data/data";
export default {
  name: "Solutions",
  data() {
    return {
      solutions: data.solutions,
    };
  },
  mounted() {
    console.log(this.solutions);
  },

  methods: {
    navigation(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style scoped>
.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.card {
  background: url("../assets/images/OIG1.2bfiriisCPQ.jpeg");
  background-size: cover;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: rgb(85, 79, 79);
  margin: 20px;

  width: 500px;

  height: 350px;
  text-align: center;
  position: relative;
  overflow: hidden; /* Pour cacher le contenu qui dépasse */
  transition: width 0.5s, height 0.5s, box-shadow 0.5s; /* Ajoutez une transition */
}
.gmat {
  background: url("../assets/images/gmat3.jpg");
  background-size: cover;
  background-position: fixed;
  justify-content: center;
  text-align: center;
}

.gmat {
  background: url("../assets/images/gmat3.jpg");
  background-size: cover;
  background-position: center; /* Centrer l'image */
  background-repeat: no-repeat; /* Ne pas répéter l'image */
  display: flex;
  justify-content: center;
  align-items: center; /* Centrer verticalement */
  text-align: center;
  overflow-x: hidden; /* Cacher le débordement horizontal */
}

.budget {
  background: url("../assets/images/budget2.jpg");
  background-size: cover;
  background-position: center; /* Centrer l'image */
  background-repeat: no-repeat; /* Ne pas répéter l'image */
  display: flex;
  justify-content: center;
  align-items: center; /* Centrer verticalement */
  text-align: center;
  overflow-x: hidden; /* Cacher le débordement horizontal */
}
.vware {
  background: url("../assets/images/vware2.jpg");
  background-size: cover;
  background-position: center; /* Centrer l'image */
  background-repeat: no-repeat; /* Ne pas répéter l'image */
  display: flex;
  justify-content: center;
  align-items: center; /* Centrer verticalement */
  text-align: center;
  overflow-x: hidden; /* Cacher le débordement horizontal */
}
.buronumerik {
  background: url("../assets/images/buronumerik.jpg");
  background-size: cover;
  background-position: center; /* Centrer l'image */
  background-repeat: no-repeat; /* Ne pas répéter l'image */
  display: flex;
  justify-content: center;
  align-items: center; /* Centrer verticalement */
  text-align: center;
  overflow-x: hidden; /* Cacher le débordement horizontal */
}
.dataged {
  background: url("../assets/images/OIG3.jpeg");
  background-size: cover;
  background-position: center; /* Centrer l'image */
  background-repeat: no-repeat; /* Ne pas répéter l'image */
  display: flex;
  justify-content: center;
  align-items: center; /* Centrer verticalement */
  text-align: center;
  overflow-x: hidden; /* Cacher le débordement horizontal */
}

.card:hover {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1); /* Ajoutez une ombre lorsqu'on survole */
  /* width: 510px; */
  z-index: 100;
  /* height: 365px; */
  cursor: pointer;
  transform: translateY(-10px);
  transition: all 0.5s ease-out;
}

.info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
}

.card h2 {
  margin-top: 20px;
}

.card p {
  margin-bottom: 20px;
}

.card div {
  margin-bottom: 10px;
  font-style: italic;
}

/* Styles du titre */
.titre {
  font-size: 36px; /* Taille de la police */
  color: #333;
  color: #fff;

  text-transform: uppercase; /* Convertit le texte en majuscules */
  text-align: center; /* Centre le texte horizontalement */
  margin-top: 10px; /* Marge supérieure */
  position: relative; /* Position relative pour ajouter un effet 3D */
  background-color: #777;
  background-color: #198754;

  border-radius: 5px;
}

/* Effet 3D avec text-shadow */
.titre::after {
  content: attr(data-content); /* Utilisez le contenu du titre */
  position: absolute; /* Position absolue par rapport au titre */
  left: 0; /* Position à gauche */
  top: 0; /* Position en haut */
  z-index: -1; /* Déplacez l'ombre derrière le titre */
  color: #ddd; /* Couleur de l'ombre */
  text-shadow: 2px 2px 0 #999, /* Ombre légère en bas à droite */ 4px 4px 0 #888,
    /* Ombre plus foncée */ 6px 6px 0 #777,
    /* Ombre encore plus foncée */ 8px 8px 0 #666; /* Ombre la plus foncée */
  transform: skew(-15deg); /* Inclinaison de l'ombre */
}
</style>
