const services = {
  services: [
    {
      id: 1,
      title: "CONSULTING IT PROJECTS",
      image:
        "https://afreak.net/wp-content/uploads/2023/08/cabinet-agence-conseil-transformation-numerique-afrique-benin.jpg",
      description:
        "Bénéficiez de notre expertise en consulting pour mener à bien vos projets et atteindre vos objectifs.",
    },
    {
      id: 2,
      title: "SERVICE FASO CLOUD",
      image:
        "https://cdn.oshara.ca/wpsite/2023/09/26130536/WEB-HEBERGEMENT-compressed.jpg",
      description:
        "DOMAINES NAME, HÉBERGEMENT SITE WEB-SERVEURS CLOUDS, MACHINES VIRTUELS SÉCURISÉES SUR INTERNET. SERVICES MESSAGERIE PRO BUSINESS WORKSPACE(GOOGLE & MICROSOFT ) ",
    },
    {
      id: 3,
      title: "LE LEARNING CENTER",
      image:
        " https://afreak.net/wp-content/uploads/2023/08/cabinet-agence-conseil-transformation-numerique-afrique-benin.jpg",
      description:
        "LEARNING CENTER, c'est un en- semble de catalogues de forma- tions professionnelles, ouverts et accessibles a un large public. Les thématiques de formation sont choisies parmi les outils et techno- logies modernes. Les formations sont organisées en Ateliers.... ",
    },
  ],
};
export default services;
