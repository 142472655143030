<template>
  <div class="container">
    <h1 class="titre">NOS SERVICES</h1>

    <div class="card-list">
      <div
        @click="navigation('single-service', service.id)"
        v-for="(service, index) in services"
        :key="index"
        class="card service"
      >
        <div class="card-header">
          <h2>{{ service.title }}</h2>
        </div>
        <img :src="service.image" alt="" />
        <div class="card-body">
          <p>{{ service.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import services from "../data/services";
export default {
  data() {
    return {
      services: services.services,
    };
  },
  mounted() {
    this.services = services.services;
  },

  methods: {
    navigation(route, param) {
      this.$router.push({ name: route, params: { service: param } });
    },
  },
};
</script>

<style scoped>
/* Styles du titre */
.titre {
  font-size: 36px; /* Taille de la police */
  color: #333; /* Couleur du texte */
  color: #fff;

  text-transform: uppercase; /* Convertit le texte en majuscules */
  text-align: center; /* Centre le texte horizontalement */
  margin-top: 10px; /* Marge supérieure */
  position: relative; /* Position relative pour ajouter un effet 3D */
  background-color: #777;
  background-color: #198754;

  border-radius: 5px;
}

/* Effet 3D avec text-shadow */
.titre::after {
  content: attr(data-content); /* Utilisez le contenu du titre */
  position: absolute; /* Position absolue par rapport au titre */
  left: 0; /* Position à gauche */
  top: 0; /* Position en haut */
  z-index: -1; /* Déplacez l'ombre derrière le titre */
  color: #ddd; /* Couleur de l'ombre */
  text-shadow: 2px 2px 0 #999, /* Ombre légère en bas à droite */ 4px 4px 0 #888,
    /* Ombre plus foncée */ 6px 6px 0 #777,
    /* Ombre encore plus foncée */ 8px 8px 0 #666; /* Ombre la plus foncée */
  transform: skew(-15deg); /* Inclinaison de l'ombre */
}
.container {
  /* max-width: 800px; */
  margin: 100%;
  margin: auto;
}

/* .service {
  margin-bottom: 30px;
} */

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.6;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  background-color: #fff; /* Couleur de fond */
  border: none; /* Supprimer la bordure */
  border-radius: 10px; /* Arrondir les coins */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre douce */
  color: #555; /* Couleur du texte */
  padding: 5px;
  margin: 10px;
  text-align: center;
  transition: width 0.5s, height 0.5s, box-shadow 0.5s;
  width: 90%; /* Par défaut, une colonne sur les petits écrans */
}
.card:hover {
  cursor: pointer;
  transform: translateY(-10px);
  transition: all 0.5s ease-out;
}

/* Deux colonnes sur les écrans moyens à larges */
@media (min-width: 768px) {
  .card {
    width: 45%;
  }
}

/* Ajustement pour les très grands écrans */
@media (min-width: 1200px) {
  .card {
    width: 30%;
  }
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
}
</style>
