<template>
  <nav class="navbar">
    <div class="container-menu">
      <router-link to="/">ACCUEIL</router-link>
      <router-link to="/nos-sulutions">SOLUTIONS</router-link>
      <router-link to="/nos-services">SERVICES</router-link>
      <router-link to="/actualites">ACTUALITES</router-link>

      <router-link to="/contact">CONTACT</router-link>
    </div>
  </nav>

  <router-view />

  <footer>
    <p>
      <a href="http://sitcomint.com">SITCOM Int</a>
    </p>
  </footer>
</template>

<style>
.navbar {
  background-color: #333;
  background-color: #043463;
  color: #d3dce5;
  padding: 10px 0;
  /* position: fixed; */
  /* top: 0; */
  width: 100%;
  /* z-index: 1000; */
  margin-bottom: 10px;
  overflow-x: auto;
  /* Ajoutez un défilement horizontal en cas de dépassement de la largeur */
}

.container-menu {
  display: flex;
  justify-content: flex-start;
  /* justify-content: space-around; */
}

.navbar a {
  font-weight: bold;
  color: #d3dce5;
  padding: 10px;
  margin: 5px;
  text-decoration: none; /* Supprimez le soulignement par défaut */
  transition: background-color 0.3s ease;
  border-radius: 5px;
  text-align: center;
}

.navbar a:hover {
  background-color: #011d0f;
}

.navbar a.router-link-exact-active {
  background-color: #011d0f;
}

footer {
  width: 100%;
  height: 50px;
  background-color: #043463;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: fixed;
  bottom: 0;
  right: 0;
  left: 0; */
}
footer a {
  color: #fff;
}
</style>
